import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Smart Send Times">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The smart send time feature in Simple RM helps you get more reviews from the same number of review requests. It achieves this by sending requests at times when customers are most likely to convert.`}</p>
    <h2 {...{
      "id": "how-do-we-choose-send-times"
    }}>{`How do we choose send times?`}</h2>
    <p>{`Simple RM has sent a lot of review requests and kept track of which ones did and didn’t result in reviews. Because of this we are able to look at the data collected to see which dates and times resulted in the most reviews.`}</p>
    <p>{`This information allows us to make predictions about which date and time will have the best results. That is when review requests are sent.`}</p>
    <h2 {...{
      "id": "how-do-you-start-using-the-feature"
    }}>{`How do you start using the feature?`}</h2>
    <p>{`The best part is you do not need to do anything to take advantage of this feature. Any time a review request is triggered for a customer the feature begins to work automatically.`}</p>
    <p>{`Simple RM never sends a review request right when you trigger it. We always wait until the next optimal send time comes around and send all the review requests then.`}</p>
    <p>{`Smart Send Times allows you to trigger review requests whenever it is convenient for you, because customers will still always receive them at ideal times from them.`}</p>
    <p>{`Leverage Smart Send Times now by `}<a parentName="p" {...{
        "href": "/help-center/send-review-requests"
      }}>{`triggering a review request`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      